<template>
  <b-modal
    id="set-invoice-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
    @shown="getData"
  >
    <div class="modal-header">
      <div class="modal-title">Vincular fatura</div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="12">
          <b-form-group id="health-plan-select">
            <validation-observer>
              <label class="label" for="health-plan-select">Convênio</label>
              <b-form-input
                v-model="healthPlan.fantasy_name"
                placeholder="Selecionar"
                disabled
              />
            </validation-observer>
          </b-form-group>
        </b-col>

        <b-col cols="8">
          <b-form-group id="invoice-select">
            <validation-observer>
              <div class="label">
                <label for="invoice-select"> Fatura </label>
              </div>
              <multiselect
                id="invoices"
                v-model="selectedInvoice"
                :options="invoicesOptions"
                class="with-border"
                :showLabels="false"
                track-by="label"
                label="label"
                placeholder="Selecionar"
                @select="onSelectInvoice"
                :allow-empty="false"
                ref="multiselect"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noOptions"> Nenhuma </template>
                <template slot="afterList">
                  <li @click="onCreateNewInvoice">
                    <div class="multiselect__option new-invoice">
                      <Plus class="icon" />
                      Criar nova fatura
                    </div>
                  </li>
                </template>
              </multiselect>
            </validation-observer>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group id="closing-date">
            <validation-observer>
              <div class="label">
                <label for="closing-date"> Data de fechamento </label>
              </div>
              <date-picker
                ref="datepicker"
                class="full"
                format="DD/MM/YYYY"
                :disabled-date="isPastDate"
                v-model="closingDate"
                placeholder="DD/MM/AAAA"
                :disabled="!newInvoice"
              ></date-picker>
            </validation-observer>
          </b-form-group>
        </b-col>

        <b-col cols="12" class="my-3 d-flex justify-content-end">
          <b-button variant="outline-danger" class="mr-3" @click="closeModal">
            Cancelar
          </b-button>
          <b-button variant="primary" v-if="canSave" @click="handleSave">
            Vincular e salvar
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { TissInvoice } from '@/modules/tiss/manageGuides/utils/statuses'
import { isPastDate } from '@/utils/dateHelper'

export default {
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Plus: () => import('@/assets/icons/plus.svg')
  },
  props: {
    selectedGuides: Array
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      closingDate: null,
      selectedInvoice: null,
      invoicesOptions: [],
      healthPlan: {
        id: null,
        fantasy_name: ''
      },
      newInvoice: false
    }
  },
  computed: {
    canSave() {
      return this.selectedInvoice && this.closingDate
    }
  },
  methods: {
    isPastDate,
    async getData() {
      if (this.selectedGuides.length > 0) {
        this.healthPlan =
          this.selectedGuides[0].invoicing_tiss_setting.clinic_health_plan.health_plan
      }
      await this.getInvoicingNumbers()
    },
    closeModal() {
      this.newInvoice = false
      this.$bvModal.hide('set-invoice-modal')
    },
    onCreateNewInvoice() {
      const isLoading = this.$loading.show()
      const clinicHealthPlanId =
        this.selectedGuides[0].invoicing_tiss_setting.clinic_health_plan_id
      this.$refs.multiselect.toggle()
      this.api
        .getNextNumberByHealthPlanId(clinicHealthPlanId)
        .then(res => {
          this.selectedInvoice = {
            label: res.data,
            value: null,
            closingDate: null
          }
          this.closingDate = null
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          isLoading.hide()
          this.newInvoice = true
        })
    },
    handleSave() {
      if (!this.newInvoice) {
        this.saveGuidesInvoiceId()
        return
      }
      const isLoading = this.$loading.show()
      const payload = {
        clinic_health_plan_id:
          this.selectedGuides[0].invoicing_tiss_setting.clinic_health_plan_id,
        closing_date: this.closingDate,
        status: TissInvoice.INVOICE_STATUS_OPEN
      }
      this.api
        .createInvoicingTissInvoice(payload)
        .then(res => {
          this.selectedInvoice.value = res.data.id
          this.selectedInvoice.closingDate = res.data.closing_date
          this.newInvoice = false
          this.saveGuidesInvoiceId()
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
      isLoading.hide()
    },
    async saveGuidesInvoiceId() {
      const isLoading = this.$loading.show()
      const payload = {
        invoice_id: this.selectedInvoice.value,
        guide_ids: this.selectedGuides.map(guide => guide.id)
      }
      this.api
        .updateInvoiceIdManyGuides(payload)
        .then(res => {
          this.$toast.success(res.data)
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          isLoading.hide()
          this.$emit('onSave')
          this.closeModal()
        })
    },
    onSelectInvoice(invoice) {
      this.closingDate = new Date(invoice.closingDate + 'T00:00:00')
      this.newInvoice = false
    },
    async getInvoicingNumbers() {
      const isLoading = this.$loading.show()

      try {
        const { data } = await this.api.getInvoicingTissNumbers(
          this.healthPlan.id,
          this.clinic.id,
          false
        )
        this.invoicesOptions = []
        data.forEach(invoice => {
          this.invoicesOptions.push({
            label: invoice.number,
            value: invoice.id,
            closingDate: invoice.closing_date
          })
        })
        this.invoicesOptions.sort(
          (current, previous) => previous.label - current.label
        )
        this.closingDate = null
        this.selectedInvoice = this.invoicesOptions.find(
          invoice => invoice?.value === this.selectedGuides[0].tiss_invoice_id
        )
        if (this.selectedInvoice) {
          this.onSelectInvoice(this.selectedInvoice)
        }
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    }
  }
}
</script>

<style lang="scss">
#set-invoice-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;

      .form-group {
        label {
          font-size: 1rem;
          font-weight: 600;
        }

        .form-control {
          font-size: 1rem;
          color: var(--type-active);
        }

        .options-content,
        .select-option-container {
          font-size: 1rem;
          font-weight: 500;
          color: var(--type-active);
        }

        .disabled {
          pointer-events: none;

          .form-control {
            background: var(--neutral-100);
          }
        }
        .new-invoice {
          color: var(--blue-500);
          font-weight: bold;
          display: flex;
          align-items: center;
        }

        .icon {
          width: 20px;
          height: 20px;
          fill: var(--blue-500);
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
